<template>
  <v-app>
    <v-app-bar app flat>
      <v-layout row>
        <div class="logo ml-3 mr-5" @click="reset">
          <img :title="appName" src="@/assets/images/logo.png" />
        </div>

        <SearchBar />
        <div style="margin-left: auto"></div>
        <History />
        <UserMenu />
        <v-btn
          to="/about"
          icon
          class="ml-n2"
          :title="$t('about.button')"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </v-layout>
    </v-app-bar>

    <v-main>
      <v-layout style="height: 100%" justify-center align-center>
        <v-col cols="10">
          <v-row id="logo-container" class="mb-10" justify="center">
            <div class="logo-about">
              <img src="@/assets/images/logo_tu_braunschweig.svg" />
            </div>
            <div class="logo-about">
              <img src="@/assets/images/logo_ulb_darmstadt.jpg" />
            </div>
            <div class="logo-about">
              <img src="@/assets/images/logo_tib.svg" />
            </div>
            <div class="logo-about">
              <img src="@/assets/images/logo_fraunhofer.gif" />
            </div>
          </v-row>
          <v-row class="mb-10" justify="center">
            <v-col justify="center">
              <h2>{{ $t('about.about_heading') }}</h2>
              <i18n class="text-justify" path="about.text" tag="p">
                <template #iart>
                  <a href="https://www.iart.vision/">iArt</a>
                </template>
                <template #visualanalytics>
                  <a href="https://www.tib.eu/en/research-development/research-groups-and-labs/visual-analytics">{{ $t('about.va_group') }}</a>
                </template>
                <template #github>
                  <a href="https://github.com/TIBHannover/iart">GitHub</a>
                </template>
              </i18n>

              <i18n class="text-justify" tag="p" path="about.fidbau">
                <template #braunschweig>
                  <a href="https://www.tu-braunschweig.de/en/ub">{{ $t('about.braunschweig_lib') }}</a>
                </template>
                <template #darmstadt>
                  <a href="https://www.ulb.tu-darmstadt.de/die_bibliothek/index.en.jsp">{{ $t('about.darmstadt_lib') }}</a>
                </template>
                <template #tib>
                  <a href="https://www.tib.eu/en/">{{ $t('about.tib') }}</a>
                </template>
                <template #fraunhofer>
                  <a href="https://www.bau.fraunhofer.de/en.html">{{ $t('about.fraunhofer_irb') }}</a>
                </template>
              </i18n>

              <h2>{{ $t('about.contact_heading') }}</h2>
              <p class="text-justify">
                {{ $t('about.contact') }}
                <a href="mailto:info@fid-bau.de">
                  info@fid-bau.de
                </a>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-layout>
    </v-main>

    <HelpButton />
    <Footer />
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
    };
  },
  methods: {
    load() {
      this.$store.dispatch('api/load');
    },
    reset() {
      this.$store.commit('api/removeAllFilters');
      this.$store.commit('api/updateQuery', []);
      this.$store.dispatch('api/load');
    },
  },
  computed: {
    filters() {
      return this.$store.state.api.filters;
    },
  },
  watch: {
    filters: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
  components: {
    Footer: () => import('@/components/Footer.vue'),
    History: () => import('@/components/History.vue'),
    UserMenu: () => import('@/components/UserMenu.vue'),
    SearchBar: () => import('@/components/SearchBar.vue'),
    HelpButton: () => import('@/components/HelpButton.vue'),
  },
};
</script>

<style scoped>
.logo {
  align-items: center;
  cursor: pointer;
  display: flex;
}

#logo-container {
  column-gap: 50px;
  row-gap: 25px;
}
.logo > img {
  max-height: 28px;
}

.logo-about {
  align-items: center;
  display: flex;
}

h2 {
  margin-bottom: 10px;
  display: block;
}
h3 {
  margin-bottom: 2px;
  display: block;
}

.logo-about > img {
  max-height: 56px;
}
</style>
